.header {
  height: 56px;
  display: flex;
}

.backButtonContainer {
  display: flex;
  width: 56px;
  height: 56px;
}

.backButton {
  cursor: pointer;
  display: flex;
  width: 56px;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  border-right: 1px solid rgba(0,0,0,.1);
}

.backIcon {
  width: 14px;
}