#root {
  min-height: 100vh;
}

legend {
  font-size: 1rem;
}

.form-striped label {
  margin-top: 1rem;
}

.form-striped .form-row:nth-of-type(even) {
  background-color: rgba(0,0,0,.05);
}

.sticky-action-bar {
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(242, 242, 242);
}