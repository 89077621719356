.searchResult {
  margin-top: 2rem;
}

.drugForm {
  width: auto;
}

.questions {
  margin-top: 1rem;
}