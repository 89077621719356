.questionGroup {
  border: 1px solid #ddd;
  /* border-top: none; */
  padding: 0 15px 15px;
  margin-bottom: 15px;
  border-radius: 8px;
}

.questionGroupTitle {
  border-radius: 15px;
  font-size: 1.1rem;
  font-weight: bold;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
  padding: 15px;
  margin-left: -15px;
  margin-right: -15px;
}